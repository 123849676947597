import { useTranslation } from 'next-i18next';
import Link from 'next/link';
import SearchIcon from 'svg/search.svg';

export default function SearchTerms() {
  const { t } = useTranslation();

  const searchTerms = [
    {
      href: '/vacatures?query=Administratief+Medewerker',
      label: 'Administratief Medewerker',
    },
    { href: '/vacatures?query=Verkoopmedewerker', label: 'Verkoopmedewerker' },
    { href: '/vacatures?query=werkvoorbereider', label: 'Werkvoorbereider' },
    { href: '/vacatures?query=bedrijfsleider', label: 'Bedrijfsleider' },
    { href: '/vacatures?query=horeca', label: 'Horeca' },
    { href: '/vacatures?query=onderwijs', label: 'Onderwijs' },
    { href: '/vacatures?query=monteur', label: 'Monteur' },
    { href: '/vacatures?query=hr', label: 'HR' },
  ];

  return (
    <div className="c-search-terms">
      <div className="o-retain o-retain--desk u-padding-right-none u-padding-left-none">
        <h2 className="c-search-terms__title">{t('searchTerms.title')}</h2>
        <ul className="o-list-clean c-search-terms__list u-margin-bottom-none">
          {searchTerms.map(({ href, label }) => (
            <li key={href} className="c-search-terms__item">
              <Link href={href} className="c-search-terms__link">
                <SearchIcon className="c-search-terms__icon" />
                {label}
              </Link>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}
