import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useTranslation } from 'next-i18next';
import Layout from 'components/Layout';
import hasAuthToken from 'lib/hasAuthToken';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import SearchTerms from '@organisms/SearchTerms/SearchTerms';
import FilterHero from '@organisms/FilterHero/FilterHero';
import { ROUTES } from 'lib/constants';
import SearchIcon from 'svg/search.svg';
import UploadCvSection from '@organisms/UploadCvSection/UploadCvSection';
import ContentGrid from '@organisms/ContentGrid/ContentGrid';
import DatabaseVisual from 'img/database-visual.png';
import JobMarketingVisual from 'img/job-marketing-visual.png';
import ApplicantsVisual from 'img/applicants-visual.png';
import WerkenBijVisual from 'img/werken-bij-visual.png';
import FullBannerSection from '@organisms/FullBannerSection/FullBannerSection';

const Homepage = () => {
  const { t } = useTranslation();
  const [numberOfVacancies, setNumberOfVacancies] = useState();

  useEffect(() => {
    axios(`${process.env.FRONTEND_ENDPOINT}/api/elastic/job-postings`).then(
      ({ data }) => setNumberOfVacancies(data?.totalResults)
    );
  }, []);

  return (
    <Layout
      title={t('homepage.siteTitle')}
      metaDescription={t('homepage.metaDescription')}
      metaKeywords={t('homepage.metaKeywords')}
      metaRobots={t('homepage.metaRobots')}
    >
      <FilterHero
        title={t('homepage.heroTitle')}
        cta={{
          label: t('homepage.heroCtaLabel'),
          href: ROUTES.vacancies,
          icon: SearchIcon,
        }}
        formTitle={t('homepage.heroFormTitle', { count: numberOfVacancies })}
      />

      <SearchTerms />

      <UploadCvSection />

      <ContentGrid
        withTopPadding
        title={`Nieuwe kandidaten vinden?\nZo gedaan.`}
        label="Voor werkgevers"
        text="deBanenSite.nl is veel meer dan 500.000 actuele CV's en 25.000 openstaande vacatures."
        cta={{
          label: 'Meer voor werkgevers',
          url: ROUTES.addVacancy,
        }}
        items={[
          {
            id: 'grid-1',
            title: 'CV database',
            text: 'Waarom wachten op sollicitaties als je direct de beste kandidaten kunt benaderen? Met onze uitgebreide cv-database heb je toegang tot duizenden actuele profielen. Vind sneller de juiste match en bespaar kostbare tijd in het wervingsproces.',
            image: {
              src: DatabaseVisual,
              alt: '',
              width: 622,
              height: 474,
            },
          },
          {
            id: 'grid-2',
            title: 'Jobmarketing',
            text: 'Met onze jobmarketing tool verspreid je jouw vacatures in één keer via relevante kanalen — van vacaturesites en social media. Alles vanuit één handig systeem, waarmee je tijd bespaart én je bereik vergroot. Geen gedoe met losse platforms of verschillende inloggegevens, maar één centrale plek voor al je wervingscampagnes.',
            image: {
              src: JobMarketingVisual,
              alt: '',
              width: 438,
              height: 389,
            },
          },
          {
            id: 'grid-3',
            title: 'Sollicitanten beheren',
            text: 'Houd overzicht en werk efficiënter met onze tool voor sollicitatiebeheer. Alle binnenkomende reacties op je vacatures komen centraal binnen in één systeem — overzichtelijk, eenvoudig te beheren en toegankelijk voor het hele team.',
            image: {
              src: ApplicantsVisual,
              alt: '',
              width: 607,
              height: 425,
            },
          },
          {
            id: 'grid-4',
            title: 'Werken-bij site',
            text: 'Ontdek onze eenvoudig te gebruiken werken-bij site editor en creëer zelf in no-time een aantrekkelijke werken-bij site — zonder technische kennis of codeer-skills.',
            image: {
              src: WerkenBijVisual,
              alt: '',
              width: 966,
              height: 523,
            },
          },
        ]}
      />

      <FullBannerSection
        title="Meer weten?"
        cta={{ label: 'Bel ons op 072-5744544', href: 'tel:0725744544' }}
      />
    </Layout>
  );
};

export async function getServerSideProps({ req, res, locale }) {
  if (!hasAuthToken(req)) {
    res.setHeader(
      'Cache-Control',
      'public, max-age=300, s-maxage=300, stale-while-revalidate=600'
    );
  }

  return {
    props: {
      ...(await serverSideTranslations(locale, ['common'])),
    },
  };
}

export default Homepage;
