import Button from 'components/Button';
import { ROUTES } from 'lib/constants';
import Visual from 'svg/upload-cv-visual.svg';

export default function UploadCvSection() {
  return (
    <section className="c-upload-cv">
      <div className="o-retain o-retain--wall c-upload-cv__container">
        <div className="c-upload-cv__visual-container">
          <Visual className="c-upload-cv__visual" />
        </div>
        <div className="c-upload-cv__content">
          <h2>Upload je CV</h2>
          <p>
            Geen vacature kunnen vinden? Draai het om en laat werkgevers naar
            jou zoeken!
          </p>
          <Button
            extraClasses="c-button--secondary"
            label="Voeg je CV toe aan onze database"
            href={ROUTES.uploadCv}
          />
        </div>
      </div>
    </section>
  );
}
